import { graphql } from 'gatsby';
import React from 'react';
import Icon from '../atoms/icon';
import Seo from '../organisms/seo';

const iconsSvgList = ({ data: { iconSvgList } }) => {
  const iconKeys = Object.values(iconSvgList);
  const iconsTotNum = iconKeys.length;
  const iconsFilledNum = Object.values(iconSvgList).filter(
    (icon) => icon['icon-type'] === 'filled'
  ).length;
  const iconsStrokedNum = iconsTotNum - iconsFilledNum;

  const getIconType = (icon) => {
    const currentIcon = iconSvgList[icon] || '';
    return currentIcon['icon-type'] || '';
  };

  function copyToClipboard(element) {
    let inputItem = document.createElement('input');
    inputItem.value = element.replace(/\s/g, '').replace('#', '');
    document.getElementsByTagName('body')[0].append(inputItem);
    inputItem.select();
    document.execCommand('copy');
    inputItem.remove();
  }

  return (
    <>
      <Seo
        title='Icone SVG | Cattolica Assicurazioni'
        robots='noindex, nofollow'
        link='/icons-svg-list'
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }
          svg {
            width: 100%;
            height: 100%;
            max-width: 100px;
            fill: currentColor;
          }
          .svg-icon--custom-stroke {
            fill: none;
            stroke: currentColor;
            stroke-width: 2;
          }
          .svg-box {
            font-size: 12px;
            line-height: 1;
          }
          .svg-box svg {
            height: 60px;
          }
          .svg-box .svg-box__name {
            margin-top: 8px;
            margin-bottom: 8px;
          }
          .svg-box .svg-box__id {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 4px;
          }
          .svg-box .svg-box__type {
            font-size: 11px;
          }
          .svg-box .svg-box__btn {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            border: none;
            color: #fff;
            background-color: #0b5fff;
            border-color: #0b5fff;
            box-shadow: none;
            padding: 0.4375rem 0.75rem;
            font-size: 0.875rem;
            line-height: 1.15;
            border-radius: 0.1875rem;
            font-weight: 600;
            margin-top: 10px;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
              border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          }
          .svg-box .svg-box__btn:hover {
            cursor: pointer;
            background-color: #0053f0;
            border-color: transparent;
            color: #fff;
            text-decoration: none;
          }
          .svg-box .svg-box__btn:focus {
            box-shadow: 0 0 0 0.125rem #FFF, 0 0 0 0.25rem #80acff;
          }
          .container {
            margin-bottom: 32px;
          }
          .row {
            display: grid;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            column-gap: 16px;
            row-gap: 64px;
            margin: 0 20px;
          }
          .text-center {
            text-align: center;
          }
          .counter {
            width: calc(100% - 40px);
            margin: 20px;
            border: 4px solid #00324d;
            text-align: center;
            font-size: 1.5em;
            padding: 20px;
          }
          .counter #counter {
            font-weight: bold;
            color: #00324d;
          }
          .icons-num {
            font-size: 0.8em;
          }
        `,
        }}
      />
      <div className='container'>
        <div className='counter'>
          Total number of SVG icons: <span id='counter'>{iconsTotNum} </span>
          <span className='icons-num'>
            ({iconsStrokedNum} stroked and {iconsFilledNum} filled)
          </span>
        </div>
        <div className='row'>
          {iconKeys.map((curIcon) => (
            <div key={curIcon?.icon}>
              <div className='svg-box text-center'>
                <Icon icon={curIcon} thickness='normal' />
                <div id={curIcon?.icon} className='svg-box__name'>
                  <div className='svg-box__id'>{curIcon?.icon}</div>
                  <div className='svg-box__type'>
                    ({getIconType(curIcon?.icon)})
                  </div>
                </div>
                <button
                  type='button'
                  title={`Copia ${curIcon?.icon}`}
                  className='svg-box__btn'
                  onClick={() => {
                    copyToClipboard(curIcon?.icon);
                  }}
                >
                  Copia
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default iconsSvgList;

export const query = graphql`
  query {
    iconSvgList
  }
`;
